import { lang } from "./Dictionary"

export const studentLvlData = {
  1: { color: "#57DD9D", img: require("../assets/img/achievements/lvl1.png") },
  2: { color: "#7E50CD", img: require("../assets/img/achievements/lvl2.png") },
  3: { color: "#15A1CD", img: require("../assets/img/achievements/lvl3.png") },
  4: { color: "#D8394D", img: require("../assets/img/achievements/lvl4.png") },
  5: { color: "#D8394D", img: require("../assets/img/achievements/lvl5.png") },
  6: { color: "#FECC4E", img: require("../assets/img/achievements/lvl6.png") },
  7: { color: "#57DD9D", img: require("../assets/img/achievements/lvl1.png") },
}

export const booksCategoriesImages = {
  Animals: require("../assets/img/categories/Animals.png"),
  audiobooks: require("../assets/img/categories/audiobooks.png"),
  Arts: require("../assets/img/categories/Arts.png"),
  Classics: require("../assets/img/categories/Classics.png"),
  Dinosaurs: require("../assets/img/categories/Dinosaurs.png"),
  Fiction: require("../assets/img/categories/Fiction.png"),
  "Fun!": require("../assets/img/categories/Fun.png"),
  Music: require("../assets/img/categories/Music.png"),
  Nature: require("../assets/img/categories/Nature.png"),
  phonics: require("../assets/img/categories/Phonics.png"),
  Scary: require("../assets/img/categories/Scary.png"),
  Science: require("../assets/img/categories/Science.png"),
  Space: require("../assets/img/categories/Space.png"),
  Sports: require("../assets/img/categories/Sports.png"),
  Technology: require("../assets/img/categories/Technology.png"),
  USA: require("../assets/img/categories/USA.png"),
  Us: require("../assets/img/categories/Us.png"),
  World: require("../assets/img/categories/World.png"),
}

export const modalBookImages = [
  require("../assets/img/modal/Assessment.png"),
  require("../assets/img/modal/Practice.png"),
]

export const answersScheme = [
  // { letter: 'A', rgb: '88,100,255' },
  // { letter: 'B', rgb: '255,161,52' },
  // { letter: 'C', rgb: '53,177,255' },
  // { letter: 'D', rgb: '255, 77, 151' },
  { letter: "A", rgb: "53, 177, 255" },
  { letter: "B", rgb: "53, 177, 255" },
  { letter: "C", rgb: "53, 177, 255" },
  { letter: "D", rgb: "53, 177, 255" },
  { letter: "E", rgb: "53, 177, 255" },
  { letter: "F", rgb: "53, 177, 255" },
  { letter: "G", rgb: "53, 177, 255" },
  { letter: "H", rgb: "53, 177, 255" },
  { letter: "I", rgb: "53, 177, 255" },
  { letter: "J", rgb: "53, 177, 255" },
]

export const coinsAddedConcepts = {
  practice: lang("constants_concepts_book_read"),
  practice_again: lang("constants_concepts_book_read_again"),
  exam: lang("constants_concepts_exam"),
  practice_session: lang("constants_concepts_practice_session"),
}

export const reviewImages = [
  require("../assets/img/review/Review0.png"),
  require("../assets/img/review/Review1.png"),
  require("../assets/img/review/Review2.png"),
  require("../assets/img/review/Review3.png"),
]

export const roles = {
  STUDENT: 0,
  PARENT: 1,
  SIMPLE_PARENT: 2,
}

export const langs = ["eng", "esp"]

export * from "../assets/videos/videoLangExports"

export const EVENT_TYPES = {
  READING: "reading",
  EXAM: "exam",
  DECODING: "decoding",
  PRACTICE: "practice",
  VOCABULARY: "vocabulary",
  GRAMMAR: "grammar",
  CONVERSATION: "conversation",
}

export const BOOK_FILE_TYPES = {
  PDF: "pdf",
  EPUB: "epub",
}
