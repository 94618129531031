import React from 'react';

const Posibilities = [
  {
    backgrounds: [
      require('../assets/img/loginBackground/Rocket.png')
    ],
    lotties: [
      "/lotties/loginTori.json"
    ]
  },
  {
    backgrounds: [
      require('../assets/img/loginBackground/Planets.png')
    ],
    lotties: [
      "/lotties/loginDiego.json"
    ]
  },
  {
    backgrounds: [
      require('../assets/img/loginBackground/Space.png')
    ],
    lotties: [
      "/lotties/loginSam.json"
    ]
  },
];

export default class LoginImage extends React.Component {
  constructor(props) {
    super(props);
    const choice = Posibilities[Math.floor(Math.random() * Posibilities.length)];
    this.background = choice.backgrounds[Math.floor(Math.random() * choice.backgrounds.length)];
    this.lottie = choice.lotties[Math.floor(Math.random() * choice.lotties.length)];
  }

  render() {
    return <div style={{ width: '100%', height: '100%', backgroundSize: 'cover', backgroundImage: `url("${this.background}")` }}>
      <lottie-player src={this.lottie} style={{ height: '100vh', alignSelf: 'center' }} loop autoplay />
    </div>
  }
}