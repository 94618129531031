import React from "react"
import { studentLvlData } from "../helpers/Constants"
import Colors from "../helpers/Colors"
import ColoredBar from "./ColoredBar"
import { Card, CardBody, Row, Col } from "reactstrap"
import PlayAudio from "../helpers/PlayAudio"
import { lang } from "../helpers/Dictionary"

export default class LvlCard extends React.Component {
  onCardClick = () => {
    if (this.props.onPress) {
      PlayAudio("Drop")
      this.props.onPress()
    }
  }
  render() {
    return (
      <Card
        className={this.props.onPress ? "scalableHover s" : ""}
        style={{
          ...{
            cursor: this.props.onPress && "pointer",
            width:
              this.props.width || (window.innerWidth >= 992 ? "60%" : "100%"),
            marginBottom: "0",
          },
          ...this.props.style,
        }}
        onClick={this.onCardClick}
      >
        <CardBody>
          <Row style={{ alignItems: "center", justifyContent: "space-around" }}>
              <img
                src={studentLvlData[this.props.actualLvl].img}
                alt={lang("lvl_badge", { actualLvl: this.props?.actualLvl })}
                width={50}
                height={55}
              />
              <Col
                style={{
                  marginLeft: 20,
                  paddingRight: 30,
                  justifyContent: "space-between",
                  height: 40,
                }}
              >
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: 16, color: Colors.TEXT }}>
                    {lang("level_actualLvl", {
                      actualLvl: this.props?.actualLvl,
                    })}
                  </span>
                <Row style={{ alignItems: "center" }}>
                  <img
                    src={require("../assets/img/Coins.png")}
                    alt={lang("coins")}
                    width={22}
                    height={20}
                  />
                  <span
                    style={{
                      fontSize: 14,
                      color: Colors.CAPTION_GRAY,
                      marginLeft: 5,
                    }}
                  >
                    {this.props.coins}
                  </span>
                </Row>
                </Row>
                <ColoredBar
                  color={studentLvlData[this.props.actualLvl].color}
                  size={this.props.points / this.props.nextLvl}
                  style={{ position: "absolute" }}
                />
              </Col>
            </Row>
        </CardBody>
      </Card>
    )
  }
}
